@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ffe4dd;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff9800;
}